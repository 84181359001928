<template>
  <div v-if="importQueueSize > 1">
    <div class="d-flex align-center justify-lg-space-between pa-5">
      <div class="mr-4">
        <p class="mb-0 text-body-1">
          {{ $tc('component.bookmarks.import.in_progress') }}
          <span
            v-if="importPercentage > 0 && showPercentage"
            class="font-weight-semibold pl-2"
          >
            {{ importPercentage }}%
          </span>
        </p>
      </div>
    </div>
    <v-progress-linear color="blue400" size="24" indeterminate />
  </div>

  <div v-else>
    <div class="d-flex align-center justify-lg-space-between pa-5">
      <div class="mr-4">
        <p class="mb-0 text-body-1">
          <span>{{ $tc('component.bookmarks.import.done') }}</span>
          <button
            class="ml-2 teal600--text cursor-pointer text-decoration-underline mb-0 font-weight-medium text-body-1"
            @click="handleRefreshClick"
          >
            {{ $tc('component.bookmarks.import.load_items') }}
          </button>
        </p>
      </div>
      <BrandButton color="neutral900" small icon @click="closeToast">
        <PhX class="text-h5 neutral400--text" />
      </BrandButton>
    </div>
  </div>
</template>

<script>
import { PhX } from 'phosphor-vue'

export default {
  components: { PhX },
  props: {
    showPercentage: {
      type: Boolean,
      default: true,
    },
    isRunning: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 10000,
    },
    lastImportSize: {
      type: [Number],
      default: 0,
    },
    importQueueSize: {
      type: [Number],
      default: 0,
    },
  },
  data: () => ({
  }),
  computed: {
    importPercentage() {
      if (this.lastImportSize === 0) {
        return 0
      }

      const percentage = ((this.lastImportSize - this.importQueueSize) / this.lastImportSize) * 100

      if (percentage < 1) {
        return 1
      }

      return Math.floor(percentage)
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    closeToast() {
      this.$emit('close')
    },
    handleRefreshClick() {
      window.location.reload()
    },
  },
}
</script>
