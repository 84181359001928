<template>
  <v-snackbar
    v-bind="$attrs"
    v-model="snackbar"
    :last-import-size="lastImportSize"
    :import-queue-size="importQueueSize"
    app rounded="0" :timeout="-1"
    class="cursor-pointer active-import-notice"
    content-class="full-width d-flex align-center pa-0"
  >
    <div v-if="importQueueSize > 1" class="snackbar-content full-width">
      <v-container class="max-app-width pa-4">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex align-center">
            <span>{{ $tc('component.bookmarks.import.in_progress') }}</span>
            <span v-if="importPercentage > 1" class="font-weight-semibold pl-2">{{ importPercentage }}%</span>
          </v-col>
        </v-row>
      </v-container>
      <v-progress-linear class="full-width prgress-bar" indeterminate />
    </div>

    <div v-else class="snackbar-content full-width">
      <v-container class="max-app-width pa-4">
        <v-row dense align="center">
          <v-col cols="auto" class="d-flex align-center">
            <span>{{ $tc('component.bookmarks.import.done') }}</span>
          </v-col>
          <v-spacer />
          <v-col cols="auto" class="d-flex align-center">
            <BrandButton color="neutral900" small icon @click="snackbar = false">
              <PhX class="text-h5 neutral400--text" />
            </BrandButton>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-snackbar>
</template>

<script>
import { PhX } from 'phosphor-vue'

export default {
  components: { PhX },
  inheritAttrs: false,
  props: {
    lastImportSize: {
      type: [Number],
      default: 0,
    },
    importQueueSize: {
      type: [Number],
      default: 0,
    },
  },
  data: () => ({
    snackbar: true,
  }),
  computed: {
    importPercentage() {
      if (this.lastImportSize === 0) {
        return 0
      }
      if (this.importQueueSize >= this.lastImportSize) {
        return 1
      }
      const percentage = ((this.lastImportSize - this.importQueueSize) / this.lastImportSize) * 100
      return Math.floor(percentage)
    },
  },
}
</script>

<style lang="scss" scoped>
.active-import-notice :deep() {
  .v-snack__wrapper {
    margin: 0;
  }

  .v-snack__action {
    display: none;
  }
}
.snackbar-content {
  position: relative;
}
.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
}
</style>
