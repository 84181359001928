import { stateGetters, stateSetters, watchGetterAndPersist } from '@/utils/store/store-utils'

import Collection, { SHOW_COUNT } from '@/store/models/collections/Collection'
import CollectionMember from '@/store/models/collections/CollectionMember'
import Bookmark from '@/store/models/bookmarks/Bookmark'
import { COLLECTION_SORT_FIELDS, collectionsFilterDefaults, ITEM_LAYOUT_STYLE } from '@/utils/constants'
import Preferences from '@/plugins/preferences'
import EventBus from '@/plugins/eventbus'
import inviteActions from './inviteActions'
import membershipActions from './membershipActions'

const defaultState = () => ({
  cursor: null,
  show: SHOW_COUNT,
  listStyle: ITEM_LAYOUT_STYLE.cards,
  filters: {
    search: '',
    sorting: [COLLECTION_SORT_FIELDS.createdAt, false],
  },
})

export default {
  name: 'collections',
  namespaced: true,
  state: defaultState(),
  getters: {
    ...stateGetters(defaultState()),
    defaultFilterFn() {
      return collectionsFilterDefaults
    },
    filters(s, g) {
      const { filters } = s
      return filters ?? g.defaultFilterFn()
    },
    sorting(_, g) {
      return g.filters.sorting
    },
  },
  mutations: {
    ...stateSetters(defaultState()),
    reset(state) {
      const s = defaultState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setFilters(state, { filters }) {
      state.filters = {
        ...state.filters,
        ...filters,
      }
    },
  },
  actions: {
    setSortingAction({
      commit, getters,
    }, val) {
      const { filters } = getters
      commit('setFilters', { filters: { ...filters, sorting: val } })
    },
    async getCollections({ getters, rootGetters }, params = {}) {
      const { results, next } = await this.$api.collection.get({
        params: { next: getters.cursor, limit: 10, ...params },
      })
      const withIsSelected = results.map(res => ({ ...res, isSelected: rootGetters['bulkActions/isAllSelected'] }))
      await Collection.insertOrUpdate({ data: withIsSelected })

      return { results, next }
    },
    async searchCollections(_, params) {
      const { results, next } = await this.$api.collection.get({ params })
      return { results, next }
    },
    async getById(_, id) {
      const collection = await this.$api.collection.getId(id)
      await Collection.insertOrUpdate({ data: collection })

      return collection
    },
    async deleteCollection(_, collection) {
      await this.$api.collection.remove(collection.id)

      await Collection.delete(collection.id)
      await CollectionMember.delete(i => i.collectionId === collection.id)
      await Bookmark.delete(i => i.ownerId === collection.id)

      return true
    },
    async editCollection(_, { id, data }) {
      const result = await this.$api.collection.edit(id, data)
      await Collection.update({ where: id, data: result })
      return result
    },
    async createCollection(_, data) {
      return this.$api.collection.create({
        name: data.name,
        description: data.description,
        defaultSorting: data.defaultSorting,
        defaultView: data.defaultView,
        parent: data.parent || null,
        access: data.access || [],
      })
    },
    ...inviteActions,
    ...membershipActions,
  },
}

EventBus.$once('app:created', async vm => {
  const listStyle = await Preferences.get('collectionStyle')
  if (ITEM_LAYOUT_STYLE[listStyle]) {
    vm.$store.commit('collections/setListStyle', ITEM_LAYOUT_STYLE[listStyle])
  }

  watchGetterAndPersist(vm.$store, 'collections/listStyle', 'collectionStyle')
})
