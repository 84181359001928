import EventBus from '@/plugins/eventbus'
import Preferences from '@/plugins/preferences'
import { stateGetters, stateSetters, watchGetterAndPersist } from '@/utils/store/store-utils'

import Bookmark, { SHOW_COUNT } from '@/store/models/bookmarks/Bookmark'
import {
  ITEM_LAYOUT_STYLE, MAX_READ_TIME, bookmarkFilterDefaults, collectionBookmarkFilterDefaults,
} from '@/utils/constants'
import BookmarkTag from '@/store/models/bookmarks/BookmarkTag'

const refetchQueue = new Set()

const defaultState = () => ({
  listStyle: ITEM_LAYOUT_STYLE.cards,
  collectionListStyle: ITEM_LAYOUT_STYLE.cards,
  collectionId: null,
  // stats
  bookmarkCount: null,
  duplicateCount: null,
  popularTags: null,
  languages: null,
  deletedBookmarkCount: null,
  filteredBookmarkCount: null, // INFO: bookmarkCount is the total bookmark count without any filter applied
  importQueueSize: null,
  sumImportQueue: null,
  lastImportSize: null,
  // pagination
  next: null,
  show: 0,
  // filters
  filters: {},
  filterOpen: false,
})

export default {
  name: 'bookmarks',
  namespaced: true,
  state: defaultState(),
  getters: {
    ...stateGetters(defaultState()),
    isCollection(s) {
      return !!s.collectionId
    },
    defaultFilterFn(s, g) {
      return g.isCollection ? collectionBookmarkFilterDefaults : bookmarkFilterDefaults
    },
    collectionId(s, g, rs, rootGetters) {
      return s.collectionId ?? rootGetters['auth/collectionId']
    },
    currentRouteCollectionId(s) {
      return s.collectionId
    },

    filters: (s, g) => {
      const { filters } = s
      return filters[g.collectionId] ?? g.defaultFilterFn()
    },
    sorting: (s, g) => g.filters.sorting,
  },
  mutations: {
    ...stateSetters(defaultState()),
    setFilters(state, { collectionId, filters }) {
      state.filters = {
        ...state.filters,
        [collectionId]: structuredClone(filters),
      }
    },
    reset(state) {
      state.collectionId = null
      state.next = null
      state.show = 0
      state.filters = {}

      state.bookmarkCount = null
      state.importQueueSize = null
      state.sumImportQueue = null
      state.lastImportSize = null
      state.filteredBookmarkCount = null
      state.duplicateCount = null
      state.languages = null
      state.popularTags = null
    },
  },
  actions: {
    // filters
    setupFilters({ commit, rootGetters }, { collectionId, filters }) {
      const isCollection = collectionId !== rootGetters['auth/collectionId']
      commit('setFilters', {
        collectionId,
        filters: filters || (isCollection ? collectionBookmarkFilterDefaults() : bookmarkFilterDefaults()),
      })
    },
    setFilters({ commit, getters }, val) {
      commit('setNext', null)
      commit('setFilters', { collectionId: getters.collectionId, filters: val })
    },
    setSorting({
      state, commit, getters, dispatch,
    }, val) {
      const { collectionId, filters } = getters
      if (!state.filters[collectionId]) {
        dispatch('setupFilters', { collectionId })
      }

      commit('setFilters', {
        collectionId,
        filters: { ...filters, sorting: val },
      })
    },
    setFilterTags({
      state, commit, getters, dispatch,
    }, v) {
      const { collectionId, filters } = getters
      if (!state.filters[collectionId]) {
        dispatch('setupFilters', { collectionId })
      }

      commit('setFilters', {
        collectionId,
        filters: {
          ...filters,
          tags: v,
        },
      })
    },
    async validateFilterTags({ getters, dispatch }) {
      const { filters: { tags }, collectionId } = getters
      if (!tags.length) return

      const validTags = await this.$api.bookmark.getTagsById(collectionId, {
        params: { ids: tags },
      })

      // insert whichever tags we received into store for future use
      await BookmarkTag.insertOrUpdate({ data: validTags })

      // update filters with valid tags
      if (tags.length !== validTags.length) {
        console.warn('Some tags in url filters were not found in collection', tags, validTags)
        dispatch('setFilterTags', validTags.map(i => i._id))
      }
    },
    // READ
    async getBookmarks({ getters, dispatch, rootGetters }, searchOpts = {}) {
      const { filters, collectionId, isCollection } = getters

      const readTime = [
        filters.readTime[0] || false,
        filters.readTime[1] >= MAX_READ_TIME ? false : filters.readTime[1],
      ]

      const isFavorite = !isCollection && filters.isFavorite
      const search = filters.search?.trim()

      const { results, next, bookmarkCount } = await this.$api.bookmark.get(collectionId, {
        params: {
          next: getters.next,
          limit: SHOW_COUNT,
          sortBy: filters.sorting[0],
          asc: filters.sorting[1],
          tags: filters.tags,
          ...(isFavorite && { isFavorite }),
          ...(search?.length >= 2 && { search }),
          ...(readTime.some(Boolean) && { readTime }),
          ...(filters.languages.length && { languages: filters.languages }),
          ...searchOpts,
        },
      })

      await Bookmark.insertOrUpdate({
        data: results.map(i => ({
          ...i,
          isPrivate: !isCollection,
          isSelected: rootGetters['bulkActions/isAllSelected'],
        })),
      })

      // queue isLoading bookmarks up for refetch
      results.filter(i => i.isLoading).forEach(i => dispatch('refetchBookmark', { collectionId, id: i._id }))

      return { results, next, bookmarkCount }
    },
    async getDeleted({ getters, rootGetters }, searchOpts = {}) {
      const { collectionId, isCollection } = getters

      const { results, next, bookmarkCount } = await this.$api.bookmark.get(collectionId, {
        params: {
          next: getters.next,
          limit: 10,
          sortBy: 'deletedAt',
          isDeleted: true,
          asc: false,
          ...searchOpts,
        },
      })

      await Bookmark.insertOrUpdate({
        data: results.map(i => ({
          ...i,
          isPrivate: !isCollection,
          isSelected: rootGetters['bulkActions/isAllSelected'],
        })),
      })

      return { results, next, bookmarkCount }
    },
    async getId({ rootGetters, dispatch }, { collectionId, id }) {
      const bookmark = await this.$api.bookmark.getId(collectionId, id)

      await Bookmark.insertOrUpdate({
        data: {
          ...bookmark,
          isPrivate: collectionId === rootGetters['auth/collectionId'],
          isSelected: rootGetters['bulkActions/isAllSelected'],
        },
      })

      if (bookmark.isLoading) {
        dispatch('refetchBookmark', { collectionId, id })
      }

      return bookmark
    },
    // NOTE this should be replaced with websocket pushing, but not part of MVP
    async refetchBookmark({ dispatch, rootGetters }, { collectionId, id, attempt = 0 }) {
      if (refetchQueue.has(id)) {
        // if bookmark is already being refetched, don't try again
        if (!attempt) return
      } else {
        refetchQueue.add(id)
      }

      const bookmark = await this.$api.bookmark.getId(collectionId, id)

      // update existing bookmark, don't insert
      await Bookmark.update({
        where: id,
        data: { ...bookmark, isPrivate: collectionId === rootGetters['auth/collectionId'] },
      })

      // successfully refetched, stop trying
      if (!bookmark.isLoading) {
        refetchQueue.delete(id)
        return
      }

      if (attempt < 6) {
        setTimeout(() => {
          dispatch('refetchBookmark', { collectionId, id, attempt: attempt + 1 })
        }, 4e3 * (attempt + 1))

        return
      }

      console.error(`Failed to refetch bookmark ${id} after ${attempt} attempts`, bookmark)
      refetchQueue.delete(id)
    },
    // CREATE
    async createBookmark({ getters }, form) {
      const collectionId = form.collectionId ?? getters.collectionId
      const ownerData = await this.$api.bookmark.create(collectionId, form.data)

      return ownerData
    },
    // stats
    async getStats({ commit, getters }) {
      const stats = await this.$api.bookmark.stats(getters.collectionId)
      commit('setDeletedBookmarkCount', stats.deletedBookmarkCount)
      commit('setBookmarkCount', stats.bookmarkCount)
      commit('setImportQueueSize', stats.importQueueSize)
      commit('setSumImportQueue', stats.sumImportQueue)
      commit('setLastImportSize', stats.lastImportSize)
      commit('setDuplicateCount', stats.duplicateCount - stats.duplicateUrls)

      const languages = Object.entries(stats.languages).map(([code, count]) => ({ code, count }))
      commit('setLanguages', languages)

      return stats
    },
    // tags
    async getPopularTags({ commit, getters }) {
      const tags = await this.$api.bookmark.popularTags(getters.collectionId)

      await BookmarkTag.insertOrUpdate({ data: tags })
      commit('setPopularTags', tags.map(tag => ({ id: tag._id, count: tag.count })))

      return tags
    },
    async searchTags({ getters }, options) {
      const collectionId = options.collectionId || getters.collectionId
      const { results, next } = await this.$api.bookmark.searchTags(collectionId, {
        params: options,
      })
      await BookmarkTag.insertOrUpdate({ data: results })

      return { results, next }
    },
    async getTagsById({ getters }, ids) {
      const tags = await this.$api.bookmark.getTagsById(getters.collectionId, { params: { ids } })
      await BookmarkTag.insertOrUpdate({ data: tags })
      return tags
    },
    // import / export
    async import({ getters, dispatch }, file) {
      const result = await this.$api.bookmark.importBookmarks(getters.collectionId, file)
      dispatch('auth/getMe', null, { root: true }).catch(console.error)
      return result
    },
    async export({ getters }, { format, email = undefined }) {
      return this.$api.bookmark.exportBookmarks(getters.collectionId, {
        type: format,
        ...(email && { email }),
      })
    },
  },
}

EventBus.$once('app:created', async vm => {
  const listStyle = await Preferences.get('bookmarkStyle')
  if (ITEM_LAYOUT_STYLE[listStyle]) {
    vm.$store.commit('bookmarks/setListStyle', ITEM_LAYOUT_STYLE[listStyle])
  }

  watchGetterAndPersist(vm.$store, 'bookmarks/listStyle', 'bookmarkStyle')

  // extension syncing
  if (BUILD_TARGET === 'extension') {
    (await import('webextension-polyfill')).runtime.onMessage.addListener(async ({ event, data }) => {
      switch (event) {
        case 'app:new-bookmark':
          await vm.$store.dispatch('bookmarks/getId', {
            id: data.bookmarkId,
            collectionId: data.collectionId,
          })
          break
        default: break
      }
    })
  }
})
