<template>
  <v-container class="pa-4 pt-md-12 page-content">
    <v-row>
      <!-- Desktop layout -->
      <template v-if="isDesktop && !isExtensionPopup">
        <v-col cols="12">
          <h1 class="text-h5 mb-2 font-weight-semibold">
            <span>{{ $t('page.bookmarks.all.title') }}</span>
          </h1>

          <h3 v-if="bookmarkCount !== null && !loading.bookmarks" class="text-body-2 neutral600--text">
            <span v-if="!hasActiveFilters"> {{ $tc('common.bookmarks_n', bookmarkCount) }}</span>
            <span v-if="hasActiveFilters"> {{
              $tc(
                'common.bookmarks_n_filtered',
                bookmarkCount,
                {filteredBookmarkCount, bookmarkCount})
            }}
            </span>
            <!-- <template v-if="stats.duplicateCount">
              <span class="mx-1">&ndash;</span>
              <span>{{ stats.duplicateCount }} possible duplicates</span>
            </template> -->
          </h3>
          <v-skeleton-loader v-else max-width="300" type="text" />
        </v-col>
      </template>

      <!-- Cross platform filter buttons -->
      <v-col v-if="!isBulkActionsActive" cols="12">
        <v-row>
          <v-col :cols="(isMobile || isExtensionPopup) && searchFocused ? 12 : 'undefined'">
            <SearchButtonInput
              :value="filters.search"
              :loading="loading.bookmarks"
              :placeholder="$tc('cta.search_bookmark', 1)"
              :minified="$vuetify.breakpoint.xs"
              @input="onSearchInput"
              @focused="searchFocused = $event"
            />
          </v-col>

          <template v-if="!searchFocused || !(isMobile || isExtensionPopup)">
            <v-spacer v-if="!(isMobile || isExtensionPopup) || !filters.search" />

            <v-col cols="auto" class="filter-buttons" :class="isExtensionPopup && 'is-extension-popup'">
              <BookmarkFilterSwitcher class="mr-3" @click.native="onOpenFilter" />
              <BookmarksSortingSwitcher :sorting="sorting" class="mr-3" @set="setSorting" />
              <BookmarksStyleSwitcher :list-style="listStyle" class="mr-3" @set="setListStyle" />
              <BookmarkSelectSwitcher @click.native="toggleBulkActions" />
            </v-col>
          </template>
        </v-row>
      </v-col>

      <BookmarkBulkActions v-if="isBulkActionsActive" :options="bulkActionOptions" />

      <v-col v-if="isDesktop && !isExtensionPopup" cols="12">
        <v-divider class="neutral50 my-2" />
      </v-col>

      <v-col v-if="!loading.bookmarks && !bookmarks.length" cols="12" class="pt-4 pt-md-8">
        <BookmarkNoResults :filters-active="filtersActive" @reset="resetFilters()" />
      </v-col>

      <v-col v-else cols="12" class="bookmarks">
        <BookmarkList
          :loading="loading.bookmarks"
          :bookmarks="bookmarks"
          :user-id="userId"
          :list-style="listStyle"
          @action="onBookmarkAction"
          @filter-tags="setFilterTags"
        />
      </v-col>

      <v-col
        v-if="next"
        cols="12" class="text-center"
      >
        <BrandButton
          variant="secondary"
          x-large class="px-12"
          :loading="loading.bookmarks"
          @click="refreshBookmarks()"
        >
          {{ $t("cta.load_more") }}
        </BrandButton>
      </v-col>
    </v-row>

    <BookmarkFilterIndicator
      v-if="isMobile || isExtensionPopup"
      :value="(bookmarks.length || loading.bookmarks) && filtersActive"
      @click.native="onOpenFilter"
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import {
  PhStar,
  PhTrash,
  PhTagSimple,
  PhListPlus,
} from 'phosphor-vue'
import BookmarkView from './mixins/BookmarkView'
import BulkActionsMixin from './mixins/BulkActions'
import BookmarkNoResults from './components/BookmarkNoResults.vue'

// NOTE in my opinion, all bookmark actions should be moved to popups (mobile: fullscreen popups)
// instead of having their own subpages and routes to go along with them
// right now, clicking on comments or add-to-collection takes you away from this route
// which unloads all bookmarks and filters, once you're done with your bookmark action
// you get redirected here and lose all your scrolling progress and filters
// this is especially annoying on mobile, where you have to scroll all the way down again
// a lot of the UI can also be simplified drastically by just using simple popups
// but this is just my frontend developer rant

export default {
  metaInfo() {
    return {
      title: this.$t('page.bookmarks.all.title'),
    }
  },
  components: { BookmarkNoResults },
  mixins: [BookmarkView, BulkActionsMixin],
  data: () => ({
    loading: {
      import: false,
    },
  }),
  computed: {
    bulkActionOptions() {
      return [
        {
          text: 'bulk.actions.add_to_favorites',
          icon: PhStar,
          color: 'neutral900--text',
          action: this.addBulkToFavorites,
          weight: 'fill',
        },
        {
          text: 'bulk.actions.remove_from_favorites',
          icon: PhStar,
          color: 'neutral900--text',
          action: this.removeBulkToFavorites,
        },
        {
          text: 'bulk.actions.add_to_collections',
          icon: PhListPlus,
          color: 'neutral900--text',
          action: this.addBulkToCollections,
        },
        {
          text: 'bulk.actions.add_tags',
          icon: PhTagSimple,
          color: 'neutral900--text',
          action: this.bulkAddTags,
          weight: 'fill',
        },
        {
          text: 'bulk.actions.remove_tags',
          icon: PhTagSimple,
          color: 'neutral900--text',
          action: this.bulkRemoveTags,
        },
        {
          text: 'bulk.actions.delete_bookmarks',
          icon: PhTrash,
          color: 'red400--text',
          action: this.deleteBulkSelected,
        },
      ]
    },
  },
  created() {
    this.$bus.$on('router:error:duplicated', this.onNavDuped)
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    this.$bus.$off('router:error:duplicated', this.onNavDuped)
    window.removeEventListener('scroll', this.handleScroll)
    clearInterval(this.refreshInterval)
  },
  methods: {
    ...mapActions({
      toggleFavorite: 'bookmark/toggleFavorite',
      deleteBookmark: 'bookmark/delete',
    }),
    onBookmarkAction(bookmark, { action }) {
      switch (action) {
        case 'favorite':
          this.toggleFavorite(bookmark).catch(err => this.$error.toast(err))
          break
        case 'delete':
          this.deleteBookmark(bookmark)
            .then(() => this.$toast.info(this.$t('common.bookmark_deleted')))
            .then(this.onGetStats)
            .catch(err => this.$error.toast(err))
          break
        case 'add-to-collection':
          this.$router.push(`/bookmarks/${bookmark.id}/add-collections`)
          break
        case 'tags':
          this.$router.push(`/bookmarks/${bookmark.id}/tags`)
          break
        case 'edit':
          this.$router.push(`/bookmarks/${bookmark.id}/edit`)
          break
        case 'comments':
          this.$router.push(`/bookmarks/${bookmark.id}/notes`)
          break
        case 'share':
          bookmark.share()
          break
        case 'toggle-select':
          this.bulkToggleBookmarkSelection(bookmark)
          break
        default:
      }
    },
    onNavDuped() {
      this.resetView()
      this.loadBookmarks()
    },
    handleScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 1000

      if (bottomOfWindow && !this.loading.bookmarks && this.next) {
        this.loadBookmarks()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-content {
  padding-bottom: 50vh !important;
}

.filter-buttons.is-extension-popup {
  &:deep(.v-btn .d-sm-inline) {
    display: none !important;
  }
}

.active-filters-notice {
  z-index: 2 !important;
}
</style>
