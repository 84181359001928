/* eslint-disable import/no-cycle */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import seed from 'seed-random'
import BaseModel from '@/utils/store/BaseModel'
import Bookmark, { COLORS, SHAPES } from '@/store/models/bookmarks/Bookmark'
import BookmarkTag from '@/store/models/bookmarks/BookmarkTag'
import User from '@/store/models/user/User'
import CollectionMember from '@/store/models/collections/CollectionMember'
import CollectionShareLink from '@/store/models/collections/CollectionShareLink'
import { ITEM_LAYOUT_STYLE } from '@/utils/constants'

export const SHOW_COUNT = 10

class Collection extends BaseModel {
  static entity = 'collections'

  static fields() {
    return {
      ...super.fields(),
      name: this.string(),
      description: this.attr(null),
      defaultView: this.string(ITEM_LAYOUT_STYLE.cards),
      defaultSorting: this.attr(),
      // stats
      bookmarkCount: this.number(),
      memberCount: this.number(),
      thumbnails: this.attr([]),
      createdAt: this.attr().nullable(),
      depth: this.number(0),
      parent: this.string(null),
      // relationships
      shareLink: this.hasOne(CollectionShareLink, 'collectionId'),
      ownerId: this.string(),
      owner: this.belongsTo(User, 'ownerId'),
      bookmarks: this.hasMany(Bookmark, 'ownerId'),
      tags: this.hasMany(BookmarkTag, 'ownerId'),
      members: this.hasMany(CollectionMember, 'collectionId'),
      users: this.belongsToMany(User, CollectionMember, 'collectionId', 'userId'),
      // flags
      isSelected: this.boolean(false),
      // For sorting on frontend
      bookmarkNewestDate: this.attr().nullable(),
      bookmarkOldestDate: this.attr().nullable(),
      bookmarkExists: this.number(0),
    }
  }

  get isNested() {
    return this.parent && this.parent !== 'null'
  }

  // For sorting on frontend
  get nameLower() {
    return this.name.toLowerCase()
  }

  get thumbnailBookmarks() {
    // MVP+ this is quite hacky we could also just insert the bookmarks...
    if (!this.thumbnails.length) return []

    return this.thumbnails.map(bookmark => {
      const rand = seed(bookmark._id)()
      const colorClasses = COLORS[Math.floor(rand * COLORS.length)]
      const shape = SHAPES[Math.floor(rand * SHAPES.length)]

      const thumbnail = bookmark.image ?? bookmark.webPage?.image
      let thumbnailUrl = null
      if (thumbnail) {
        thumbnailUrl = `${CONFIG.cdnUrl}/${thumbnail}?class=bookmarkthumbnail`
      } else {
        thumbnailUrl = require(`@/assets/img/placeholders/${shape}-thumbnail.webp`)
      }

      return {
        _id: bookmark._id,
        colorClasses,
        thumbnailUrl,
        image: thumbnail,
      }
    })
  }

  setIsSelected(value) {
    this.$update({
      isSelected: value,
    })
  }
}

export default Collection
